@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'FixelDisplay';
  src: url('./fonts/FixelDisplay-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FixelDisplay';
  src: url('./fonts/FixelDisplay-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FixelDisplay';
  src: url('./fonts/FixelDisplay-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
html,
body {
    font-family: 'FixelDisplay', "Apple Color Emoji", sans-serif;
}

header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 40;
}

.main-background {
    background: black;
}

.mask {
    mask-repeat: no-repeat;
    mask-size: contain;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.mask.large {
    -webkit-mask-image: url(/assets/photoHero/AP_base_Hero.webp);
    mask-image: url(/assets/photoHero/AP_base_Hero.webp);
    mask-size: contain;
    mask-position: center;
}

@media (max-width: 1023px) {
    .mask.large {
        -webkit-mask-image: none;
        mask-image: none;
    }
}

.mask-second {
    mask-repeat: no-repeat;
    mask-size: contain;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.mask-second.large {
    -webkit-mask-image: url(/assets/icon/Article.svg);
    mask-image: url(/assets/icon/Article.svg);
    mask-size: contain;
}

@media (max-width: 1023px) {
    .mask-second.large {
        -webkit-mask-image: none;
        mask-image: none;
    }
}

.mask-dir-blog {
    -webkit-mask-image: url(/assets/form/DirectoryForm.svg);
    mask-image: url(/assets/form/DirectoryForm.svg);
    mask-repeat: no-repeat;
    mask-size: cover;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}
.mask-dir {
    mask-repeat: no-repeat;
    mask-size: cover;
    max-width: 100%;
    max-height: 100%;
}

.mask-dir.large {
    -webkit-mask-image: url(/assets/form/DirectoryForm.svg);
    mask-image: url(/assets/form/DirectoryForm.svg);
    mask-size: contain;
    mask-position: center;
}

@media (max-width: 1023px) {
    .mask-dir.large {
        -webkit-mask-image: none;
        mask-image: none;
        mask-size: initial;
    }
}
.blur-bg-gradient-green {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background: #0f645b;
    background: radial-gradient(circle, #0f645b 0%, rgba(252, 70, 107, 0) 70%);
}
.blur-bg-gradient-violet {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background-image: radial-gradient(
        circle,
        #924dff 0%,
        rgba(252, 70, 107, 0) 70%
    );
}

.blur-bg-gradient-blue {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background: #352ce8;
    background: radial-gradient(circle, #352ce8 0%, rgba(252, 70, 107, 0) 70%);
}

.blur-gradient-green {
    position: absolute;
    width: 100%;
    height: 100vh;
    border-radius: 50%;
    background: #0f645b;
    background: radial-gradient(circle, #0f645b 0%, rgba(252, 70, 107, 0) 70%);
    filter: blur(30px);
}

.blur-gradient-violet {
    position: absolute;
    width: 100%;
    height: 100vh;
    border-radius: 50%;
    background: #924dff;
    background: radial-gradient(circle, #924dff 0%, rgba(252, 70, 107, 0) 70%);
    filter: blur(30px);
}

.blur-gradient-blue {
    position: absolute;
    width: 100%;
    height: 100vh;
    border-radius: 50%;
    background: #352ce8;
    background: radial-gradient(circle, #352ce8 0%, rgba(252, 70, 107, 0) 70%);
    filter: blur(30px);
}

.custom-gradient-green-btn-h:hover {
    background: linear-gradient(321.78deg, #0f645b 23.22%, #09c6cc 110.01%);
}

.custom-gradient-violet-btn-h:hover {
    background: linear-gradient(321.77deg, #924dff 3.2%, #4b0082 93.86%);
}

.custom-gradient-blue-btn-h:hover {
    background: linear-gradient(321.96deg, #352ce8 -5.61%, #002395 93.88%);
}

.custom-gradient-green-btn {
    background: linear-gradient(321.78deg, #0f645b 25.22%, #09c6cc 110.01%);
}
.custom-gradient-violet-btn {
    background: linear-gradient(321.77deg, #4b0082 3.2%, #924dff 93.86%);
}
.custom-gradient-blue-btn {
    background: linear-gradient(321.96deg, #002395 -5.61%, #352ce8 93.88%);
}
.custom-gradient-green-btn {
    background: linear-gradient(321.78deg, #0f645b 23.22%, #09c6cc 110.01%);
}

.custom-gradient-green-text {
    background: linear-gradient(321.78deg, #0f645b 25.22%, #09c6cc 110.01%);
    -webkit-background-clip: text;
    color: transparent;
}

.custom-gradient-violet-text {
    background: linear-gradient(321.77deg, #4b0082 3.2%, #924dff 93.86%);
    -webkit-background-clip: text;
    color: transparent;
}

.custom-gradient-blue-text {
    background: linear-gradient(321.96deg, #002395 -5.61%, #352ce8 93.88%);
    -webkit-background-clip: text;
    color: transparent;
}

.custom-gradient-green-btn:hover {
    background: linear-gradient(321.78deg, #09c6cc 23.22%, #0f645b 110.01%);
}

.custom-gradient-violet-btn {
    background: linear-gradient(321.77deg, #4b0082 3.2%, #924dff 93.86%);
}

.custom-gradient-violet-btn:hover {
    background: linear-gradient(321.77deg, #924dff 3.2%, #4b0082 93.86%);
}

.custom-gradient-blue-btn {
    background: linear-gradient(321.96deg, #002395 -5.61%, #352ce8 93.88%);
}

.custom-gradient-blue-btn:hover {
    background: linear-gradient(321.96deg, #352ce8 -5.61%, #002395 93.88%);
}

.custom-gradient-violet {
    background: linear-gradient(321.77deg, #4b0082 3.2%, #924dff 93.86%);
}

.custom-gradient-blue {
    background: linear-gradient(321.96deg, #002395 -5.61%, #352ce8 93.88%);
}

.custom-gradient-green {
    background: linear-gradient(321.78deg, #0f645b 23.22%, #09c6cc 110.01%);
}

.vertical-divider {
    position: relative;
    padding-left: 16px; /* Add padding to make space for the vertical line */
}

.vertical-divider::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: #717171; /* Inherits the color from the parent class */
}
.vertical-divider.active::before {
    animation: fadeInOut 3s ease-in-out; /* Add the animation */
    border-radius: 24px;
}

@keyframes fadeInOut {
    0% {
        opacity: 1; /* Start hidden */
        background-color: #717171;
    }
    25% {
        opacity: 0.8; /* Start hidden */
        background-color: #fff;
    }
    50% {
        opacity: 1; /* Fully visible */
        background-color: #fff;
    }
    75% {
        opacity: 0.8; /* Fade out */
        background-color: #fff;
    }
    100% {
        opacity: 1; /* Fade out */
        background-color: #717171;
    }
}

.vertical-divider.violet::before {
    background-color: #fff;
}
.vertical-divider.green::before {
    background-color: #fff;
}
.vertical-divider.blue::before {
    background-color: #fff;
}
.vertical-divider.white::before {
    background-color: white;
}
.vertical-divider.last::before {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; /* Round the bottom of the last item */
}

.directory {
    background-image: url(/assets/form/DirectoryForContactSM.png);
    background-repeat: no-repeat;
    background-size: contain;
}
@media (min-width: 980px) {
    .directory {
        background-image: url(/assets/form/DirectoryForContact.svg);
    }
}
.directory-v2 {
    background-image: url(/assets/form/DirectoryForContactSM.png);
    background-repeat: no-repeat;
    background-size: cover;
}
@media (min-width: 1023px) {
    .directory-v2 {
        background-image: url(/assets/form/DirectoryForContactV2.png);
        width: 100%;
        height: auto;
        background-size: contain;
        background-clip: border-box;
        background-position: center;
    }
}

.directory-big {
    background-image: url(/assets/form/DirectoryForContactSM.png);
    background-repeat: no-repeat;
    background-size: cover;
}
@media (min-width: 1024px) {
    .directory-big {
        background-image: url(/assets/form/DirectoryForContact.svg);
        background-size: inherit;
        background-size: cover;
    }
}
.file-border-violet-lg {
    background-image: url(/assets/form/FileFormVioletBorderLG.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.file-violet-lg {
    background-image: url(/assets/form/FileFormVioletLG.svg);
    background-repeat: no-repeat;
    background-size: contain;
}
.file-border-violet {
    background-image: url(/assets/form/FileFormVioletBorder.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.file-violet {
    background-image: url(/assets/form/FileFormViolet.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

/* Стиль рамки за замовчуванням */
.file-border-blue {
    background-image: url(/assets/form/FileFormBlueBorder.svg);
    background-repeat: no-repeat;
    background-size: contain;
    transition:
        background-image 0.5s ease,
        background-color 0.5s ease,
        border-color 0.5s ease;
}

/* Стиль фону при скролі */
.file-blue {
    background-image: url(/assets/form/FileFormBlue.svg);
    background-repeat: no-repeat;
    background-size: contain;
    transition:
        background-image 0.5s ease,
        background-color 0.5s ease,
        border-color 0.5s ease;
}

/* Анімація при наведенні для великих екранів */
@media (min-width: 1024px) {
    .file-border-blue:hover {
        background-image: url(/assets/form/FileFormBlue.svg);
        transition:
            background-image 0.5s ease,
            background-color 0.5s ease,
            border-color 0.5s ease;
    }
}
.file-border-green {
    background-image: url(/assets/form/FileFormGreenBorderSM.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.file-green {
    background-image: url(/assets/form/FileFormGreenSM.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.file-border-green-mask {
    -webkit-mask-image: url(/assets/form/FileFormGreenBorderSM.svg);
    mask-image: url(/assets/form/FileFormGreenBorderSM.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: #0f645b;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    background-clip: content-box; /* Ensure background color respects padding */
}

.file-border-green-mask-lg {
    -webkit-mask-image: url(/assets/form/FileFormGreenBorderLG.svg);
    mask-image: url(/assets/form/FileFormGreenBorderLG.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: #0f645b;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    background-clip: content-box; /* Ensure background color respects padding */
}
.file-green-mask {
    background-image: url(/assets/form/FileFormGreenSM.png);
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    background-clip: content-box; /* Ensure background color respects padding */
}

.file-mask {
    -webkit-mask-image: url(/assets/form/FileMask.svg);
    mask-image: url(/assets/form/FileMask.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.file-mask-rotate {
    background-image: url(/assets/form/FileRotate.svg);
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    background-clip: content-box; /* Ensure background color respects padding */
}

.file-mask-lg {
    -webkit-mask-image: url(/assets/form/FileMaskLG.svg);
    mask-image: url(/assets/form/FileMaskLG.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    mask-origin: content-box;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.file-mask-xl {
    -webkit-mask-image: url(/assets/form/FileMaskXL.svg);
    mask-image: url(/assets/form/FileMaskXL.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: top;
    mask-origin: content-box;
    width: 100%;
    height: auto;
    overflow: hidden;
}
.file-mask-xl-card {
    -webkit-mask-image: url(/assets/form/FileMaskXL.svg);
    mask-image: url(/assets/form/FileMaskXL.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: top;
    mask-origin: content-box;
    width: 100%;
    height: auto;
    overflow: hidden;
}

@media (max-width: 780px) {
    .file-mask-xl-card {
        mask-size: cover;
        border-radius: 36px;
    }
}

.file-mask-xl-video {
    width: 100%;
    min-height: 450px;
    overflow: hidden;
    border-radius: 36px;
}
@media (min-width: 1024px) {
    .file-mask-xl-video {
        -webkit-mask-image: url(/assets/form/FileVideoForm.svg);
        mask-image: url(/assets/form/FileVideoForm.svg);
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: top;
        mask-origin: content-box;
    }
}

/* Контейнер для скролу */
.achievements-container {
    position: relative;
    width: 100%;
}

.achievements-content {
    display: flex;
    flex-direction: row;
    animation: scroll-left 20s linear infinite;
}

/* Анімація горизонтального скролу */
@keyframes scroll-left {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* Забезпечити безперервний скрол */
.achievements-content {
    display: flex;
    flex-direction: row;
    width: fit-content; /* Важливо для безперервного скролу */
}

/* Встановлення анімації скролу на паузу при ховері */

/* Стилі для контейнера елемента */
.achievement-item {
    position: relative;
    border-radius: 50%;
    border: white 1px solid;
    width: 300px;
    height: 300px;
    perspective: 1000px; /* Додати перспективу для обертання */
}

/* Стилі для обгортки всередині елемента */
.achievement-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d; /* Додати 3D стиль для обертання */
}

/* Стилі для передньої сторони */
.achievement-front,
.achievement-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Приховати зворотну сторону при обертанні */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.achievement-front--large,
.achievement-back--large {
    padding: 1rem;
}

/* Стилі для задньої сторони */
.achievement-back {
    transform: rotateY(
        180deg
    ); /* Задня сторона розгортається на 180 градусів */
}

/* Ховер ефект */
@media (hover: hover) and (pointer: fine) {
    .achievement-item:hover .achievement-inner {
        transform: rotateY(180deg);
    }
    .achievements-wrapper:hover .achievements-content {
        animation-play-state: paused;
    }
}

.achievements-wrapper.pause .achievements-content {
    animation-play-state: paused;
}

.achievement-item.clicked .achievement-inner {
    transform: rotateY(180deg);
}

.slider-container {
    overflow: hidden;
    position: relative;
}

.slider-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    transition: transform 0.5s ease-in-out;
    display: flex;
    will-change: transform;
}

.slider-item {
    flex: 1 0 33%;
    transition: transform 0.5s ease-in-out;
}

.test-hero {
    background: linear-gradient(321.78deg, #0f645b 23.22%, #09c6cc 110.01%);
}

@keyframes twinkling {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

.star-green {
    position: absolute;
    width: 15px;
    height: 15px;
    background: url("/assets/icon/star-g.svg") no-repeat center center;
    background-size: contain;
    animation: twinkling 3s infinite;
    opacity: 0.5;
    filter: drop-shadow(0 0 5px rgb(15, 100, 91));
}

.star-violet {
    position: absolute;
    width: 15px;
    height: 15px;
    background: url("/assets/icon/star-v.svg") no-repeat center center;
    background-size: contain;
    animation: twinkling 3s infinite;
    opacity: 0.5;
    filter: drop-shadow(0 0 5px rgb(146, 77, 255));
}
.star-blue {
    position: absolute;
    width: 15px;
    height: 15px;
    background: url("/assets/icon/star-b.svg") no-repeat center center;
    background-size: contain;
    animation: twinkling 3s infinite;
    opacity: 0.5;
    filter: drop-shadow(0 0 5px rgb(146, 77, 255));
}

.achievement-container {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding: 16px;
    justify-content: space-between;
    scroll-snap-type: x mandatory;
}

.achievement-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
}

.achievement-container {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.achievement-container > div {
    scroll-snap-align: center;
}

@media (max-width: 767px) {
    .achievement-container {
        justify-content: flex-start;
    }
}

.rotate-x-180 {
    transform: rotateY(180deg);
}

.backface-hidden {
    backface-visibility: hidden;
}

.transform-style-preserve-3d {
    transform-style: preserve-3d;
    perspective: 1000px;
}

@keyframes flipIn {
    0% {
        transform: rotateY(-180deg);
        opacity: 0;
    }
    100% {
        transform: rotateY(0);
        opacity: 1;
    }
}

@keyframes flipOut {
    0% {
        transform: rotateY(0);
        opacity: 1;
    }
    100% {
        transform: rotateY(180deg);
        opacity: 0;
    }
}

.flip-in {
    animation: flipIn 0.5s forwards;
}

.flip-out {
    animation: flipOut 0.5s forwards;
}

.card-container {
    perspective: 1000px;
    transform-style: preserve-3d;
}

.green-background {
    background-image: url("/assets/form/GreenFormXL.png");
    background-size: contain;
    background-repeat: no-repeat;
}
